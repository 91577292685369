.in-chapter-nav {
    align-self: flex-start; 
    display: block; 
    flex: 0 0 240px; 
    overflow-y: auto;
    max-height: 80%; 
    position: fixed; 
    top: 5rem;
    left: 1015px;
    padding-left: 0.5rem;
    padding-right: 0.1rem;
    color: grey;
    font-size: 0.75rem; 
    line-height: 1.3;
}

.level-2 {
    border-left: 1px solid #bbb;
    padding: 0.5rem 0rem 0.5rem 1rem;
}

.level-3 {
    padding: 0.6rem 0rem 0rem 1rem;
}