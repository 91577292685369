.side-drawer {
    height: 95%;
    width: 280px;
    background-color: #f0f0f0;
    color: #222;
    position: fixed;
    z-index: 200;
    overflow-y: auto;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.side-drawer-with-shadow {
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.25);
}

.drawer-item {
    padding: 0.5rem 0.75rem;
    width: 100%;
    display: flex;
    align-items: center;
}

.drawer-item-selected {
    background-color: rgb(25,101,189, 0.3);
}

.drawer-item-secondary {
    font-size: smaller;
    padding-left: 1.25rem;
    color: #444;
}

.drawer-item:hover {
    background-color: #c0c0c0;
    cursor: pointer;
}
