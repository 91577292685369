header {
    border-bottom: 0.5px solid #999;
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.25);
    padding: 0.35rem 0rem;
    background: rgb(255, 255, 255, 1);
    position: fixed;
    width: 100%;
    z-index: 1000;
}

.toolbar {
    margin: 0 auto;
    padding: 0rem 0.5rem;
    z-index: 300;
}

.toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 20px;
    width: 25px;
    background: transparent;
    border: none;
}

.toggle-button:active {
    outline: none;
    border: none;
}

.toggle-button:focus {
    outline: none;
}

.toggle-button:hover {
    cursor: pointer;
}